import React from "react";
import { FourOhFour } from "gatsby-theme-carbon";

const links = [
  { href: "/services", text: "Services" },
  { href: "/contact", text: "Contact" },
  { href: "/services/automation-iot", text: "Industrial Automation and IoT" },
  { href: "/services/web-design", text: "Web Design and Development" },
];

const Custom404 = () => <FourOhFour links={links} />;

export default Custom404;
